<template>
	<div class="main-content">
		<ktv-breadcrumb title="Dashboard" page="Dashboard" folder="Summary" />
		<b-tabs active-nav-item-class="nav nav-tabs">
			<b-tab title="Dashboard" :active="activeTabDashboard">
				<div class="text-right mb-16">
					<b-form inline class="rightside">
						<b-form-input v-model="params.columnFilters.startDate" v-validate="'required'" type="date" class="mr-2" />
						<b-form-input v-model="params.columnFilters.endDate" v-validate="'required'" type="date" class="mr-2" />
						<b-form-select v-model="params.columnFilters.partnerId" v-validate="'required'" :options="options.partner" class="mr-2" />
						<ktv-button text="Search" @click="fetchData()"></ktv-button>
					</b-form>
				</div>
				<b-row>
					<b-col lg="3" md="6" sm="12">
						<ktv-card
							:icon="iconentity"
							:subtitle="formatNumber(parseInt(values.TotalUser))"
							:desc="values.Kiosk + ' Kiosk, ' + values.Distributor + ' Distributors, ' + values.Manufacture + ' Manufactures'"
							title="User SME"
						/>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<ktv-card :icon="iconentity" :subtitle="formatNumber(parseInt(values.Transaction))" title="Customer / Buyer" />
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<ktv-card :icon="iconproduct" :subtitle="formatNumber(parseInt(values.Product))" title="Product" />
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<ktv-card
							:icon="iconentity"
							:subtitle="formatNumber(parseInt(values.TotalProduct))"
							:desc="'From ' + values.FromProduct + ' Products'"
							title="Product Value"
						/>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<ktv-card
							:icon="iconentity"
							:subtitle="formatNumber(parseInt(values.TotalTransaction))"
							:desc="'From ' + values.FromTransaction + ' Transactions'"
							title="Transaction Amount"
						/>
					</b-col>
					<b-col lg="3" md="6" sm="12">
						<ktv-card
							:icon="iconentity"
							:subtitle="formatNumber(parseInt(values.TotalExpense))"
							:desc="'From ' + values.FromExpense + ' Transactions'"
							title="Expenses"
						/>
					</b-col>
				</b-row>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
	import { KtvCard, KtvButton } from "@/components"

	export default {
		metaInfo: {
			title: "Dashboard",
		},
		components: { KtvCard, KtvButton },
		data() {
			return {
				/* eslint-disable global-require */
				iconentity: require("@/assets/images/icon/Entity.svg"),
				iconproduct: require("@/assets/images/icon/Production.svg"),
				filterchart: "1",
				activeTabDashboard: true,
				activeTabReport: false,
				options: {
					partner: [],
				},
				params: {
					columnFilters: {
						partnerId: "1",
						startDate: "2020-01-01",
						endDate: "2022-01-05",
					},
					sort: [
						{
							field: "",
							type: "",
						},
					],
					currentPage: 1,
					rowPerpage: 10,
				},
				values: {
					TotalUser: 0,
					Manufacture: 0,
					Distributor: 0,
					Kiosk: 0,
					TotalExpense: 0,
					FromExpense: 0,
					TotalProduct: 0,
					FromProduct: 0,
					TotalTransaction: 0,
					FromTransaction: 0,
					Product: 0,

					Transaction: 0,
				},
			}
		},
		mounted() {
			this.fetchData()
		},
		beforeCreate() {
			this.$http.Get([], this.$urlApi.sme.getPartner).then((res) => {
				this.options.partner = res.results
			})
		},
		methods: {
			fetchData() {
				this.$http
					.Post(this.params, this.$urlApi.dboard.dboardValue)
					.then((response) => {
						if (response.success) {
							let res = response.results
							this.values = res
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
		},
	}
</script>

<style>
	.card-icon-bg .card-body .content {
		max-width: 100% !important;
	}
	.boxreportcard {
		padding: 10px 20px;
	}
	.rightside {
		display: flex;
		justify-content: flex-end;
	}
</style>
